var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6 border-bottom pb-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v("Select Module")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.productTypes,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":""},on:{"change":_vm.moduleChange},model:{value:(_vm.benefit.product_type_id),callback:function ($$v) {_vm.$set(_vm.benefit, "product_type_id", $$v)},expression:"benefit.product_type_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('label',[_vm._v("Select Package Type")]),_c('v-radio-group',{staticClass:"benefit_type_radio",attrs:{"row":""},on:{"change":_vm.specificationChange},model:{value:(_vm.benefit.is_specific),callback:function ($$v) {_vm.$set(_vm.benefit, "is_specific", $$v)},expression:"benefit.is_specific"}},[_c('v-radio',{staticClass:"custom-radio",attrs:{"label":"General","value":0,"color":"red"}}),_c('v-radio',{staticClass:"custom-radio",attrs:{"label":"Specific","value":1,"color":"red"}})],1)],1)])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":_vm.benefit.is_specific == 1 ? 2 : 3}},[_c('label',[_vm._v("Service*")]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.venueServices,"item-text":"name","item-value":"venue_service_id","multiple":_vm.benefit.is_specific == 0,"outlined":"","rules":_vm.benefit.is_specific == 0
               ? [function (v) { return !!v[0] || 'Venue service is required'; }]
               : [function (v) { return !!v || 'Venue service is required'; }],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":_vm.getBenefitFilter},scopedSlots:_vm._u([(
               _vm.benefit.is_specific == 0 &&
                 _vm.benefit.venue_service_ids.length == _vm.venueServices.length
             )?{key:"selection",fn:function(ref){
             var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v("All Services")]):_vm._e()]}}:{key:"selection",fn:function(ref){
             var item = ref.item;
             var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},(_vm.benefit.is_specific == 0)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle('venue_service_ids', 'venueServices')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.venue_service_ids.length > 0
                       ? 'indigo darken-4'
                       : ''}},[_vm._v(_vm._s(_vm.getIcon("venue_service_ids", "venueServices")))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.benefit.venue_service_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "venue_service_ids", $$v)},expression:"benefit.venue_service_ids"}})],1),(_vm.product_type.name == 'Event')?_c('v-col',{attrs:{"md":_vm.benefit.is_specific == 1 ? 2 : 4}},[_c('label',[_vm._v(_vm._s(_vm.product_type.name + 's' + (_vm.benefit.is_specific == 1 ? '*' : '')))]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.mainFilter,"item-text":"name","item-value":"id","multiple":"","outlined":"","required":"","rules":_vm.benefit.is_specific == 1 ? [function (v) { return !!v[0] || 'Select events'; }] : [],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('event_ids')}},scopedSlots:_vm._u([(_vm.benefit.event_ids.length == _vm.mainFilter.length)?{key:"selection",fn:function(ref){
                       var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v("All "+_vm._s(_vm.product_type.name + "s"))]):_vm._e()]}}:{key:"selection",fn:function(ref){
                       var item = ref.item;
                       var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle('event_ids', 'mainFilter')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.event_ids.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon("event_ids", "mainFilter")))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.benefit.event_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "event_ids", $$v)},expression:"benefit.event_ids"}})],1):_vm._e(),(_vm.product_type.name == 'Academy')?_c('v-col',{attrs:{"md":_vm.benefit.is_specific == 1 ? 2 : 4}},[_c('label',[_vm._v(_vm._s(_vm.product_type.name + 's' + (_vm.benefit.is_specific == 1 ? '*' : '')))]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.mainFilter,"item-text":"name","item-value":"id","multiple":"","outlined":"","required":"","rules":_vm.benefit.is_specific == 1
               ? [function (v) { return !!v[0] || 'Select workshops'; }]
               : [],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('workshop_ids')}},scopedSlots:_vm._u([(_vm.benefit.event_ids.length == _vm.mainFilter.length)?{key:"selection",fn:function(ref){
               var index = ref.index;
return [(index == 0)?_c('span',[_vm._v("All "+_vm._s(_vm.product_type.name + "s"))]):_vm._e()]}}:{key:"selection",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle('workshop_ids', 'mainFilter')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.event_ids.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon("workshop_ids", "mainFilter")))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.benefit.workshop_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "workshop_ids", $$v)},expression:"benefit.workshop_ids"}})],1):_vm._e(),(_vm.product_type.name == 'Facility')?_c('v-col',{attrs:{"md":_vm.benefit.is_specific == 1 ? 2 : 4}},[_c('label',[_vm._v("Categories "+_vm._s(_vm.benefit.is_specific == 1 ? '*' : ''))]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.mainFilter,"item-text":"name","item-value":"id","required":"","outlined":"","multiple":"","rules":_vm.benefit.is_specific == 1
               ? [function (v) { return !!v || 'Categories is required'; }]
               : [],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('category_ids')}},scopedSlots:_vm._u([(_vm.benefit.category_ids.length == _vm.mainFilter.length)?{key:"selection",fn:function(ref){
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v("All Categories")]):_vm._e()]}}:{key:"selection",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle('category_ids', 'mainFilter')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.category_ids.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon("category_ids", "mainFilter")))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.benefit.category_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "category_ids", $$v)},expression:"benefit.category_ids"}})],1):_vm._e(),(_vm.product_type.name == 'Trainer')?_c('v-col',{attrs:{"md":_vm.benefit.is_specific == 1 ? 2 : 4}},[_c('label',[_vm._v("Trainers"+_vm._s(_vm.benefit.is_specific == 1 ? '*' : ''))]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.mainFilter,"item-text":"name","item-value":"id","required":"","outlined":"","multiple":"","rules":_vm.benefit.is_specific == 1
               ? [function (v) { return !!v || 'Trainers is required'; }]
               : [],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('trainer_ids')}},scopedSlots:_vm._u([(_vm.benefit.trainer_ids.length == _vm.mainFilter.length)?{key:"selection",fn:function(ref){
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v("All Trainers")]):_vm._e()]}}:{key:"selection",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle('trainer_ids', 'mainFilter')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.trainer_ids.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon("trainer_ids", "mainFilter")))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.benefit.trainer_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "trainer_ids", $$v)},expression:"benefit.trainer_ids"}})],1):_vm._e(),(_vm.product_type.name == 'Membership')?_c('v-col',{attrs:{"md":_vm.benefit.is_specific == 1 ? 2 : 4}},[_c('label',[_vm._v("Memberships "+_vm._s(_vm.benefit.is_specific == 1 ? '*' : ''))]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.mainFilter,"item-text":"name","item-value":"id","required":"","multiple":"","outlined":"","rules":_vm.benefit.is_specific == 1 ? [function (v) { return !!v || 'Type is required'; }] : [],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('membership_ids')}},scopedSlots:_vm._u([(_vm.benefit.membership_ids.length == _vm.mainFilter.length)?{key:"selection",fn:function(ref){
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v("All Memberships")]):_vm._e()]}}:{key:"selection",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle('membership_ids', 'mainFilter')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.membership_ids.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon("membership_ids", "mainFilter")))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.benefit.membership_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "membership_ids", $$v)},expression:"benefit.membership_ids"}})],1):_vm._e(),(_vm.product_type.name == 'Outlet')?_c('v-col',{attrs:{"md":_vm.benefit.is_specific == 1 ? 2 : 4}},[_c('label',[_vm._v(_vm._s(_vm.product_type.name + 's' + (_vm.benefit.is_specific == 1 ? '*' : '')))]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.mainFilter,"item-text":"name","item-value":"id","multiple":"","outlined":"","required":"","rules":_vm.benefit.is_specific == 1
               ? [function (v) { return !!v[0] || 'Select Outlets'; }]
               : [],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('outlet_ids')}},scopedSlots:_vm._u([(_vm.benefit.outlet_ids.length == _vm.mainFilter.length)?{key:"selection",fn:function(ref){
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v("All "+_vm._s(_vm.product_type.name + "s"))]):_vm._e()]}}:{key:"selection",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle('outlet_ids', 'mainFilter')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.outlet_ids.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon("outlet_ids", "mainFilter")))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.benefit.outlet_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "outlet_ids", $$v)},expression:"benefit.outlet_ids"}})],1):_vm._e(),(_vm.benefit.is_specific == 1)?_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Products*")]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.getProducts(),"multiple":"","item-text":"name","item-value":"id","required":"","outlined":"","rules":[function (v) { return v.length > 0 || 'Product is required'; }],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('product_ids')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
               var active = ref.active;
               var item = ref.item;
               var attrs = ref.attrs;
               var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
               var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-elepsis"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.main)+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},(_vm.benefit.product_ids.length == _vm.getProducts().length)?{key:"selection",fn:function(ref){
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v("All Products")]):_vm._e()]}}:{key:"selection",fn:function(ref){
               var item = ref.item;
               var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.productToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.benefit.product_ids.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.productIcon()))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.benefit.product_ids),callback:function ($$v) {_vm.$set(_vm.benefit, "product_ids", $$v)},expression:"benefit.product_ids"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"2"}},[_c('label',[_vm._v("Benefit Type*")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"required":"","rules":[function (v) { return !!v || 'Benefit Type is required'; }],"outlined":"","menu-props":{ bottom: true, offsetY: true },"item-value":"id","item-text":"name","items":[
             { id: 'P', name: 'Percentage' },
             { id: 'A', name: 'Amount' } ],"hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('benefit_type')}},model:{value:(_vm.benefit.benefit_type),callback:function ($$v) {_vm.$set(_vm.benefit, "benefit_type", $$v)},expression:"benefit.benefit_type"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.benefit.benefit_type == 'A' ? 'Amount' : _vm.benefit.benefit_type == 'P' ? 'Percentage*' : 'Promotion*'))]),_c('v-text-field',{staticClass:"q-text-field shadow-0",attrs:{"required":"","rules":_vm.benefit.benefit_type == 'A'
               ? [
                   function (v) { return !!v || 'Amount is required'; },
                   function (v) { return !isNaN(v) || 'Amount must be a number'; } ]
               : _vm.benefit.benefit_type == 'P'
               ? [
                   function (v) { return !!v || 'Percentage is required'; },
                   function (v) { return !isNaN(v) || 'Amount must be a number'; },
                   function (v) { return v <= 100 || 'Percentage should be less than 100'; } ]
               : [function (v) { return !!v || 'Promotion is required'; }],"prefix":_vm.benefit.benefit_type == 'A' ? _vm.currencyCode : '',"suffix":_vm.benefit.benefit_type == 'P' ? '%' : '',"outlined":"","hide-details":"auto","dense":"","validate-on-blur":""},on:{"change":function($event){return _vm.changeEvent('benefit')}},model:{value:(_vm.benefit.benefit),callback:function ($$v) {_vm.$set(_vm.benefit, "benefit", $$v)},expression:"benefit.benefit"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('div',{staticClass:"d-flex mt-6 gap-x-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [_c('DeleteIcon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":_vm.deleteBenefit}},'DeleteIcon',attrs,false),on))]}}])},[_c('span',[_vm._v("Delete benefit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [_c('CopyIcon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":_vm.duplicateBenefit}},'CopyIcon',attrs,false),on))]}}])},[_c('span',[_vm._v("Duplicate row")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }